<template>
  <div class="EmptySubHeader" />
</template>

<script>
export default {
};
</script>

<style lang="scss" scoped>
.EmptySubHeader {
  padding-top: $theHeaderSpacing;
  background-image: url('~@/assets/images/spruce.jpg');
  background-size: cover;
  height: 220px;
  filter: grayscale(.9);
}
</style>
